<template>
  <section class="setting-institutions-router" v-auth="'create,update,remove'">
    <el-tree
      v-loading="tree.loading"
      node-key="id"
      :data="tree.data"
      :props="{label: 'name', isLeaf: data => !data.has_child}"
      default-expand-all
      @node-click="handleNodeClick"
      :expand-on-click-node="false"
      highlight-current
    >
      <template slot-scope="{data}">
        <div class="tree-node flex">
          <div class="icon el-icon-office-building"></div>
          <div class="name" :title="data.name">{{ data.name }}</div>
        </div>
      </template>
    </el-tree>
    <div class="main">
      <div class="header">
        <div class="title">用户列表</div>
        <el-button type="primary" size="small" @click="edit('create',table.query.org_id)" v-if="auth.create">添加用户</el-button>
      </div>
      <div class="form">
        <el-table
          :data="table.data"
          :header-cell-style="{'text-align':'center'}"
          :cell-style="{'text-align':'center'}"
          row-key="id"
          default-expand-all
          v-loading="table.loading"
        >
          <el-table-column prop="index" label="序号" type="index"></el-table-column>
          <el-table-column prop="name" label="用户名称">
            <template slot-scope="scope">
              <span>{{scope.row.user.username}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="是否启用" v-if="auth.update">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.status"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="1"
                :inactive-value="2"
                @change="changeStatus(scope.row)"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="role.name" label="身份"></el-table-column>
          <el-table-column prop="org.name" label="所属机构"></el-table-column>
          <el-table-column prop="prop" label="操作">
            <template slot-scope="scope">
              <el-button
                v-if="myself.id !== scope.row.id && auth.update"
                @click="edit('update',table.query.org_id,scope.row)"
                type="text"
                size="small"
              >修改</el-button>
              <el-button
                v-if="myself.id !== scope.row.id && auth.remove"
                @click="edit('remove',1,scope.row)"
                type="text"
                size="small"
              >删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <edit
      :title="edit_title"
      ref="edit"
      @changeShow="changeShow"
      v-if="show"
      :type="type"
      :OrgTree="tree.data"
      @getList="getList"
    ></edit>
  </section>
</template>

<script>
import edit from './edit.vue';
import Account from '@/api/setting/account';
import Public from '@/api/public';
export default {
  // 用户管理
  name: 'account',
  components: { edit },
  data() {
    return {
      table: {
        data: [],
        query: {
          page: 1,
          size: 30,
          org_id: 0,
        },
        loading: false,
      },
      edit_title: '',
      show: false,
      type: '',
      roleTree: [],
      tree: {
        loading: false,
        data: [],
      },
      myself: '',
      auth: {
        create: true,
        update: true,
        remove: true,
      },
    };
  },
  methods: {
    handleNodeClick(data) {
      this.table.query.org_id = data.id;
      return this.getList();
    },
    changeShow(show) {
      this.show = show;
    },
    edit(type, org_id, data) {
      this.type = type;
      switch (type) {
        case 'create': {
          this.changeShow(true);
          this.edit_title = '添加用户';
          this.$nextTick(() => {
            this.$refs.edit.toggle(true, org_id);
          });
          break;
        }
        case 'update': {
          this.changeShow(true);
          this.edit_title = '修改用户';
          this.$nextTick(() => {
            this.$refs.edit.toggle(true, org_id, data);
          });
          break;
        }
        case 'remove': {
          return this.remove(data);
        }
      }
    },
    getList() {
      this.table.loading = true;
      Account.list(this.table.query)
        .then((res) => {
          console.log(res.items[0]);
          this.table.data = res.items;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    changeStatus(data) {
      this.table.loading = true;
      Account.update(data)
        .then(() => {
          this.$message.closeAll();
          this.$message.success(`修改成功`);
          this.getList();
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    remove(data) {
      this.$confirm(`此操作将删除用户-${data.user.username}, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.table.loading = true;
          Account.remove(data.id)
            .then(() => {
              this.$message({
                type: 'success',
                message: '删除成功!',
              });
              return this.getList();
            })
            .finally(() => {
              this.table.loading = false;
            });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    getOrgTree() {
      Public.components({
        OrgTree: this.table.query.org_id,
      }).then((res) => {
        this.tree.data = res.OrgTree;
        this.table.query.org_id = res.OrgTree[0].id;
        return this.getList();
      });
    },
  },
  mounted() {},
  created() {
    this.myself = this.$myget('account').user;

    this.getOrgTree();
  },
  computed: {},
};
</script>

<style scoped lang="scss">
.setting-institutions-router {
  height: 100%;
  box-sizing: border-box;
  display: flex;
  // flex-direction: column;

  .el-tree {
    padding: 15px;
    min-width: 180px;
    margin-right: 15px;
    height: 100%;
    box-sizing: border-box;
    font-size: 14px;
    overflow: auto;
    &::v-deep {
      .tree-node {
        flex: 1;
        min-width: 0;
        .icon {
          margin-left: 5px;
          &:hover {
            &.el-icon-document-add,
            &.el-icon-edit-outline {
              color: $primary_default;
            }
            &.el-icon-delete {
              color: $danger;
            }
          }
          &.el-icon-office-building {
            margin-left: 0;
          }
        }
        .name {
          @include ellipsis();
          flex: 1;
          min-width: 0;
        }
      }
    }
  }
  .main {
    background-color: #fff;
    padding: 15px;
    flex: 1;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      margin-bottom: 10px;
      .title {
        color: $h4;
        font-weight: bold;
      }
      .el-button {
        display: flex;
        // justify-content: flex-start;
      }
    }
    .form {
      flex: 1;
      width: calc(100% - 30px);
      // width: 100%;
    }
  }
}
</style>
